html, body {
    margin:0;
    height:100%;
  }

  h1, img {
    display: inline-block !important;
  }

  label{
    font-weight: 500;
    font-size: 16px;
  }
/* HEADER CSS  */
#Olympus_Header{
    position: fixed;
    left: 0;
    right: 0;
    padding: 10px;
    color: white;
    z-index: 2;
    background-color: #10177747;
}


#Olympus_Body {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100vh;
}

.bgimg-1{
  background-image: url('../assets/images/Olympus-Home.jpg');
  height: 100vh;
  position: relative;
  opacity: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.shader {
  position: relative;
  overflow: hidden;
  backface-visibility: hidden; /* to force GPU performance. More on that later */
}

.shader__layer {
  background: black;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;

}

.mask {
  z-index: 1;
  background: #fff;
  background-size: cover;
  mix-blend-mode: multiply;
  /*background-image: linear-gradient(to bottom,#22f2ef00 85%,transparent), url('../assets/images/Olympus-Home.jpg');*/
}

.specular {
  z-index: 0;
  background-color: white;
  background-position: center;
  background-size: 50% 80%;
  mix-blend-mode: multiply;
  background-attachment: fixed;
  background-image: linear-gradient(180deg,#02d4e4,#08f,#ff6200,#08f,#0dd7ac);
}


#loginModal{
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  top: 111px;
}

/*MODAL STYLE*/
.modal-content{
  background-color: rgb(255 255 255 / 80%);
}

.modal-header{
  background-color: rgb(16 23 119);
  border:0;
  color: white;
}

.modal-footer{
  border:0;
}

.modal-title{
  text-transform: uppercase;
  font-weight: 500;
}

.bg-transparent{
  background-color: transparent;
  border:0px;
}

.btn-primary{
  background-color: rgb(16 23 119);
    border: 0;
    font-size: 16px;
    font-weight: 500;
}

.btn-primary:hover {
  background-color: rgba(16, 23, 119);
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);

}

.btn-close{
  color: #fff;
}

.nav-link{
  color: white !important ;
  font-size: 20px;
  font-weight: bold;
}

.bg-container {
  width:100%;
  height:100%;
}

.navbar-brand{
  color: white !important;
}

.navbar a > h1,img{
  text-align: center;
  vertical-align: middle;
}

/* FOOTER CSS  */

  .footer-dark {
    padding:50px 0;
    color:#f0f9ff;
    background: linear-gradient(100deg, #053322, #661c11);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 25s ease infinite;
    -moz-animation: AnimationName 25s ease infinite;
    animation: AnimationName 25s ease infinite;
  }
  
  @-webkit-keyframes AnimationName {
    0%{background-position:96% 0%}
    50%{background-position:5% 100%}
    100%{background-position:96% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:96% 0%}
    50%{background-position:5% 100%}
    100%{background-position:96% 0%}
}
@keyframes AnimationName {
    0%{background-position:96% 0%}
    50%{background-position:5% 100%}
    100%{background-position:96% 0%}
}

  .footer-dark h3 {
    margin-top:0;
    margin-bottom:12px;
    font-weight:bold;
    font-size:16px;
  }
  
  .footer-dark ul {
    padding:0;
    list-style:none;
    line-height:1.6;
    font-size:14px;
    margin-bottom:0;
  }
  
  .footer-dark ul a {
    color:inherit;
    text-decoration:none;
    opacity:0.6;
  }
  
  .footer-dark ul a:hover {
    opacity:0.8;
  }
  
  @media (max-width:767px) {
    .footer-dark .item:not(.social) {
      text-align:center;
      padding-bottom:20px;
    }
  }
  
  .footer-dark .item.text {
    margin-bottom:36px;
  }
  
  @media (max-width:767px) {
    .footer-dark .item.text {
      margin-bottom:0;
    }
  }
  
  .footer-dark .item.text p {
    opacity:0.6;
    margin-bottom:0;
  }
  
  .footer-dark .item.social {
    text-align:center;
  }
  
  @media (max-width:991px) {
    .footer-dark .item.social {
      text-align:center;
      margin-top:20px;
    }
  }
  
  .footer-dark .item.social > a {
    font-size:20px;
    width:36px;
    height:36px;
    line-height:36px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    box-shadow:0 0 0 1px rgba(255,255,255,0.4);
    margin:0 8px;
    color:#fff;
    opacity:0.75;
  }
  
  .footer-dark .item.social > a:hover {
    opacity:0.9;
  }
  
  .footer-dark .copyright {
    text-align:center;
    padding-top:24px;
    opacity:0.3;
    font-size:13px;
    margin-bottom:0;
  }