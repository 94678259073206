#Olympus_DashBoard{
    position: absolute;
    top: 111px;
    z-index: 1;
    height: calc(100% - 111px);
}


#Olympus_SideBar{
    background-color: rgb(16 18 119 / 30%);
    color:#fff;
}

#Olympus_MainContainer{
    background-color: rgb(16 75 119 / 30%);
    color:#fff;
}

.Olympus_SideBar_Element {
    text-align: center;
    font-size: 25px;
    padding: 20px;
    border-radius: 15px;
    background-color: rgb(255 255 255 / 80%);
    font-weight: 500;
    color: black;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 60px;
}

.Olympus_MainContainer_Content{
    background-color: rgb(255 255 255 / 80%);
    font-size: 25px;
    padding: 20px;
    border-radius: 15px;
}

.Olympus_MainContainer_Division{
    background-color: rgb(255 255 255 / 80%);
    font-size: 25px;
    padding: 20px;
    border-radius: 15px;
}

.Olympus_MainContainer_Division_Container{
    border: 5px solid #a7a6a6;
    border-radius: 10px;
    padding-bottom: 10px;
}

.Olympus_MainContainer_Division_Device_System{
    font-size: 20px;
    font-weight: bold;
    background-color: #a7a6a6;
}

.Olympus_MainContainer_Division_System_Information{
    font-size: 20px;
    font-weight: bold;
    color: black;
    border-radius: 10px;
}

.Olympus_MainContainer_Division_System_Information_Panel{
    background-color: #fff;
    color:black;
    font-size: 14px;
    border-radius: 10px;
}

.Olympus_MainContainer_Division_System_Information_Panel_Content{
    border-bottom: 1px solid #a7a6a6;
}

.Olympus_MainContainer_Division_Button_Success {
    background-color: rgb(50 120 15);
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    border: 0px;
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 60px;
}

.Olympus_MainContainer_Division_Button_Success:hover{
    background-color: rgb(65 155 20);
}

.Olympus_MainContainer_Division_Button_Stop {
    background-color: rgb(120 15 15);
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    border: 0px;
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 60px;
}

.Olympus_MainContainer_Division_Button_Stop:hover{
    background-color: rgb(200 25 25);
}

.Olympus_MainContainer_Division_Button_Info {
    background-color: rgb(16, 23, 119);
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    border: 0px;
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 60px;
}

.Olympus_MainContainer_Division_Button_Info:hover{
    background-color:rgb(40 50 210);
}

.Olympus_SideBar_Element:hover{
    background-color:#fff;
    color:black;
}

.color_black{
    color:black;
}

.font-size-16{
    font-size: 16px;
}

.text-right{
    text-align: right;
}
  